import { RaterProduct } from '@/models/opportunity/raterProductTypes';
import {
  QuoteGroupWithQuote,
  QuoteSubmissionWithGroupAndQuote,
} from '../../forms/models/quoteTypes';
import { delay } from '../utils';

export type QuoteSubmissionResult = {
  quoteSubmissionId: string;
  applicationId: string;
};

export const sendQuote = async ({
  entityId,
  raterProductToSend,
  fetchFn,
}: {
  entityId: string;
  raterProductToSend: RaterProduct[];
  fetchFn: typeof fetch;
}): Promise<QuoteSubmissionResult | null> => {
  const toSend = raterProductToSend.map((rateProduct) => {
    return {
      raterName: rateProduct.rater,
      insuranceProduct: rateProduct.product,
    };
  });
  const body = {
    quoteRequests: toSend,
  };

  const url = `apim/metarater/v1/quoting/${entityId}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetchFn(url, options);
  if (!response.ok) {
    if (response.status === 400) {
      const errMessage = await parseErrorMessage(response);
      const validationError = new Error(errMessage);
      validationError.name = 'ValidationError';
      throw validationError;
    }
    return null;
  }
  return response.json();
};

const parseErrorMessage = async (response: Response): Promise<string> => {
  try {
    const errText = await response.text();
    const errObj = JSON.parse(
      errText.replace('failed to proxy call to apim:', '').trim()
    );
    return errObj.message;
  } catch (error) {
    console.log('Error parsing error response', error);
    return '';
  }
};

export const getQuoteSubmissionsForApplication = async ({
  applicationId,
  fetchFn,
}: {
  applicationId: string;
  fetchFn: typeof fetch;
}): Promise<QuoteSubmissionWithGroupAndQuote[] | null> => {
  const url = `apim/metarater/v1/application/${applicationId}/quoteSubmission?withQuoteGroups=true&withQuotes=true`;
  const response = await fetchFn(url);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export const getQuoteGroupsForSubmission = async ({
  applicationId,
  quoteSubmissionId,
  accessToken,
}: QuoteGroupsForSubmissionParams): Promise<QuoteGroupWithQuote[] | null> => {
  const authHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };
  const options = {
    method: 'GET',
    headers: authHeaders,
  };

  const url = `apim/metarater/v1/application/${applicationId}/quoteSubmission/${quoteSubmissionId}/quoteGroup?withQuotes=true`;
  const response = await fetch(url, options);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export interface QuoteGroupsForSubmissionParams {
  applicationId: string;
  quoteSubmissionId: string;
  raterProductToSend?: RaterProduct[];
  accessToken: string;
}
const MAX_RETRIES = 20;
const RETRY_WAIT = 1000;

export const checkQuoteGroupsStatus = async (
  data: QuoteGroupsForSubmissionParams,
  maxRetry = MAX_RETRIES
): Promise<QuoteGroupWithQuote[] | null> => {
  try {
    let allCompleted = false;
    let retries = 0;
    let quoteGroups = null;
    while (!allCompleted && retries < maxRetry) {
      await delay(RETRY_WAIT);

      quoteGroups = await getQuoteGroupsForSubmission(data);
      allCompleted =
        quoteGroups?.length &&
        quoteGroups?.length === data.raterProductToSend?.length
          ? quoteGroups.every(
              (quoteGroup) =>
                quoteGroup?.status === 'Completed' &&
                (quoteGroup?.raterData || quoteGroup?.errors)
            )
          : false;

      if (!allCompleted) {
        retries += 1;
      }
    }
    const anyCompleted = quoteGroups?.length
      ? quoteGroups.some(
          (quoteGroup) =>
            quoteGroup?.status === 'Completed' &&
            (quoteGroup?.raterData || quoteGroup?.errors)
        )
      : false;

    if (!allCompleted && !anyCompleted) {
      throw new Error('Quote group status check exceeded maximum retries');
    }

    return quoteGroups;
  } catch (error) {
    console.log('Checking quote group status error', error);
    throw error;
  }
};
