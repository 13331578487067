import clientLogger from '@/controllers/logger';
import { loginRequest } from '@/services/common/config';
import {
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { NO_ACTIVE_ACCOUNT_ERROR } from './constants';

interface FetchAuthProps {
  msalInstance: Pick<
    IPublicClientApplication,
    'getActiveAccount' | 'acquireTokenSilent' | 'acquireTokenPopup'
  >;
  dispatchErrorMessage: (errorMessage: string) => void;
}
export const fetchAuthenticationResult = async ({
  msalInstance,
  dispatchErrorMessage,
}: FetchAuthProps): Promise<AuthenticationResult> => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    clientLogger.error(NO_ACTIVE_ACCOUNT_ERROR);
    throw new Error(NO_ACTIVE_ACCOUNT_ERROR);
  }

  /**
   * Attempt #2 - Silent Auth
   */
  try {
    // Attempt to get the token silently first
    // Await so we can catch errors here
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    return response;
  } catch (error) {
    if (!(error instanceof InteractionRequiredAuthError)) {
      // Something unexpected went wrong
      clientLogger.error('Something went wrong with the MSAL auth call', {
        error,
      });
      throw error;
    }
  }

  /**
   * Attempt #2 - Popup
   */
  // If the above silent call fails, fallback to popup
  try {
    // Await so we can catch errors here
    const response = await msalInstance.acquireTokenPopup(loginRequest);
    return response;
  } catch (popupError) {
    // If popup call fails, dispatch a toast error
    // Sometimes users will have popup blockers enabled that will fail auth
    if (popupError instanceof BrowserAuthError) {
      dispatchErrorMessage(popupError.errorMessage);
      clientLogger.warn(popupError.errorMessage, popupError);
    } else {
      // Something unexpected went wrong
      clientLogger.error(
        'An error occured while opening the login popup',
        popupError
      );
    }
    // Attempt #2 failed
    // Re-throw the error regardless so the caller can handle it
    throw popupError;
  }
};
