import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { FormField } from '@/services/forms/models/formTypes';
import { cn } from '@/services/lib/utils';
import { Check, ChevronDown } from 'lucide-react';
import type { FC } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { FieldError, get, useFormContext, useFormState } from 'react-hook-form';
import { useFormPageContext } from '../FormPage/FormPageContext';
import { getOptionList } from '@/controllers/common/dropdown';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { useGetDynamicUrl } from './useGetDynamicUrl';
import { clearDependentVehicleFields } from '@/services/opportunity/utils';
import { rethrowNonAuthError } from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

export type ApiDropdownProps = {
  field: FormField;
};

const ApiDropdown: FC<ApiDropdownProps> = ({ field }) => {
  const {
    id,
    name,
    display,
    placeholder,
    disabled,
    optionsBy,
    syncWith,
    highlight,
  } = field;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const authedFetch = useAuthenticatedFetch();

  const { setValue, watch, register } = useFormContext();
  const selectedValue = watch(name);
  const { errors } = useFormState();
  const error: FieldError = get(errors, name);
  const { apiOptionMap, updateApiOptionMap } = useFormPageContext();
  const { isDynamicUrlComplete, dynamicUrl } = useGetDynamicUrl(
    optionsBy || '',
    name
  );
  const apiOptionMapKey = useMemo(() => id || name, [id, name]);

  const choices = useMemo(
    () => apiOptionMap[apiOptionMapKey],
    [apiOptionMapKey, apiOptionMap]
  );

  useEffect(() => {
    if (dynamicUrl && isDynamicUrlComplete) {
      const fetchOptionList = async (optionKey: string) => {
        setLoading(true);
        try {
          const list = await getOptionList(optionKey, authedFetch);
          if (!list) {
            setHasLoadingError(true);
          } else {
            setHasLoadingError(false);
            updateApiOptionMap(id || name, list);
          }
        } catch {
          setHasLoadingError(true);
        }
        setLoading(false);
      };
      fetchOptionList(dynamicUrl)
        .catch(rethrowNonAuthError) // Assume auth errors have already been logged
        .catch((cause: unknown) =>
          clientLogger.error(
            'An error occurred while fetching option list.',
            cause
          )
        );
    }
  }, [authedFetch, dynamicUrl, isDynamicUrlComplete]);

  const isLoading = loading && dynamicUrl;
  const hasError = !dynamicUrl || hasLoadingError;
  const shouldRenderOptionsList = !isLoading && !hasError;

  const handleSelect = (newSelectedValue: string) => {
    setValue(
      name,
      newSelectedValue === selectedValue ? undefined : newSelectedValue,
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );

    if (name.includes('vehicles.')) {
      clearDependentVehicleFields(name, setValue);
    }

    if (syncWith) {
      setValue(syncWith.fieldName, newSelectedValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    setOpen(false);
  };

  register(name, { ...(field.validation ? { ...field.validation } : {}) });

  const displayText = useMemo(() => {
    // handle no value
    if (!selectedValue || !choices || choices.length === 0) {
      return placeholder;
    }

    // handle selected value not in choices & found
    const matchedDisplay = choices.find(
      (option) => String(option.key).trim() === selectedValue
    )?.display;

    if (!matchedDisplay) {
      setValue(id || name, undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (name.includes('vehicles.')) {
        clearDependentVehicleFields(name, setValue);
      }

      if (syncWith) {
        setValue(syncWith.fieldName, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      return placeholder;
    }

    return matchedDisplay;
  }, [selectedValue, choices]);

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role="combobox"
            aria-expanded={open}
            className="justify-between"
            id={id || name}
            disabled={!isDynamicUrlComplete || disabled}
            data-testid={id || name}
          >
            <span
              className={`${
                displayText === placeholder ? 'text-[#d3d3d3]' : ''
              }`}
            >
              {displayText}
            </span>
            <ChevronDown className="h-3.5 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="min-w-[236px] p-0">
          {isLoading && <div>Loading...</div>}
          {hasError && (
            <div>
              <p className="pl-3 text-sm text-light-text-error">
                Failed to load dropdown options
              </p>
            </div>
          )}
          {shouldRenderOptionsList && choices && (
            <Command>
              <CommandInput placeholder="Search option..." />
              <CommandList>
                <CommandEmpty>No options found.</CommandEmpty>
                <CommandGroup>
                  {choices.map((option) => (
                    <CommandItem
                      key={option.key}
                      value={option.key}
                      onSelect={handleSelect}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          selectedValue === option.key.toString()
                            ? 'opacity-100'
                            : 'opacity-0'
                        )}
                      />
                      {option.display}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          )}
        </PopoverContent>
      </Popover>
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
    </div>
  );
};

export { ApiDropdown };
