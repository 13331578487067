import {
  CompletedForm,
  Form,
  HeadingBanner,
} from '@bwinsurance/shared-components';
import { Submission } from '@/models/forms/submission';
import styles from './SubmissionView.module.css';
import { formatDate, formatTime } from '@/controllers/common/utils';
import { getSubmissionFormDataFromObject } from './helpers';

interface Props {
  submissionData: Submission | undefined;
  completedForm: CompletedForm | undefined;
}

const SubmissionView: React.FC<Props> = ({ submissionData, completedForm }) => {
  const savedFormData = getSubmissionFormDataFromObject(
    submissionData?.content ?? {}
  );

  return (
    <div className={styles.submission}>
      {completedForm?.apiForm.name && (
        <HeadingBanner
          title={completedForm?.apiForm.name || ''}
          subtitle={`Submitted ${formatDate(
            submissionData?.createdAt
          )} ${formatTime(submissionData?.createdAt)}`}
        />
      )}
      <div className={styles.formContainer}>
        <Form
          actions={completedForm?.formContent?.actions ?? []}
          rows={completedForm?.formContent?.rows ?? []}
          supportedLanguages={
            completedForm?.apiForm.metadata.supportedLanguages
          }
          options={{}}
          onRedirect={() => Promise.resolve()}
          onSuccess={() => Promise.resolve()}
          onSubmit={() => Promise.resolve()}
          savedData={savedFormData}
          readonly={true}
        />
      </div>
    </div>
  );
};

export default SubmissionView;
