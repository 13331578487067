import { useCallback } from 'react';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { RaterProduct } from '@/models/opportunity/raterProductTypes';
import {
  checkQuoteGroupsStatus,
  sendQuote,
} from '@/services/opportunity/network/quote';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import {
  findQuoteGroups,
  getErrorMessages,
  getSuccessToasts,
} from '@/services/forms/helpers/utils';
import { AlertToast } from '@/components/shared/alertToast';
import {
  errorIsAuthError,
  useAccessToken,
} from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

const useSendAndGetQuoteGroups = () => {
  const authedFetch = useAuthenticatedFetch();

  const { getAccessToken } = useAccessToken();

  const sendAndGetQuoteGroups = useCallback(
    async (eavEntityId: string, raterProductToSend: RaterProduct[]) => {
      let completedQuoteGroups: QuoteGroupWithQuote[] = [];
      let failedQuoteGroups: QuoteGroupWithQuote[] = [];
      const toasts: AlertToast[] = [];
      try {
        const quoteSubmission = await sendQuote({
          entityId: eavEntityId,
          raterProductToSend,
          fetchFn: authedFetch,
        });

        if (!quoteSubmission) {
          throw new Error('Failed to send to rater.');
        }
        const accessToken = await getAccessToken();

        const quoteGroups = await checkQuoteGroupsStatus({
          applicationId: quoteSubmission.applicationId,
          quoteSubmissionId: quoteSubmission.quoteSubmissionId,
          raterProductToSend,
          accessToken,
        });

        const results = findQuoteGroups({
          raterProductToSend,
          quoteGroups: quoteGroups || [],
        });
        completedQuoteGroups = results.successQuoteGroups;
        failedQuoteGroups = results.errorQuoteGroups;

        const successToasts = getSuccessToasts(completedQuoteGroups);
        toasts.push(...successToasts);

        const messages = getErrorMessages(failedQuoteGroups);
        messages.forEach((message) => {
          toasts.push({
            type: 'warning',
            text: message,
          });
        });
      } catch (error) {
        if (!errorIsAuthError(error)) {
          // Assume auth errors have already been logged
          clientLogger.error('Failed to send to rater', error);
        }
        throw error;
      }
      return { completedQuoteGroups, toasts };
    },
    [authedFetch, getAccessToken]
  );
  return { sendAndGetQuoteGroups };
};

export { useSendAndGetQuoteGroups };
