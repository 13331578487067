/* eslint-disable @typescript-eslint/no-floating-promises */
import { callCrmWhoAmI, WhoAmIResponse } from '@/controllers/common/utils';
import {
  getAgent,
  getAgentFlags,
  setAgentFlag,
} from '@/services/opportunity/network/dynamics';
import { useCallback, useEffect, useState } from 'react';
import { AgentFlags } from '@/models/agent';
import {
  errorIsAuthError,
  useAccessToken,
} from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

const loadCrmData = async (
  incAccessToken: string,
  callback: (value: WhoAmIResponse | undefined) => void
) => {
  try {
    const response = await callCrmWhoAmI(incAccessToken);
    callback(response);
  } catch (error) {
    clientLogger.log('Failed to load crm data', error);
  }
};
const loadUserAgentFlags = async (
  incUserId: string,
  incAccessToken: string,
  callback: (value: AgentFlags | undefined) => void
) => {
  const userAgent = await getAgentFlags(incUserId, incAccessToken);
  callback({
    bwFenrisLegalAcknowledgement: userAgent?.bw_fenrislegalacknowledgement,
  });
};

export const useAgentAndUserInfo = ({
  crmAgentId,
}: {
  crmAgentId?: string;
}) => {
  const [crmData, setCrmData] = useState<WhoAmIResponse | undefined>();
  const [agentName, setAgentName] = useState<string | undefined>();
  const [userAgentFlags, setUserAgentFlags] = useState<
    AgentFlags | undefined
  >();

  const { getAccessToken } = useAccessToken();

  const getAssignedAgent = useCallback(
    async (agentId: string) => {
      try {
        const accessToken = await getAccessToken();
        const agent = await getAgent(agentId, accessToken);
        setAgentName(agent?.fullname);
      } catch (error) {
        if (!errorIsAuthError(error)) {
          // Assume auth errors have already been logged
          clientLogger.error('Failed to fetch agent data', error);
        }
      }
    },
    [getAccessToken]
  );

  const confirmUserAgentFlag = useCallback(
    async (flagName: keyof AgentFlags, fetchFn: typeof fetch) => {
      if (crmData) {
        const response: AgentFlags | null = await setAgentFlag(
          crmData.UserId,
          flagName,
          fetchFn
        );

        const flagsPatch = {
          [flagName]: response?.[flagName],
        };

        setUserAgentFlags((flags) => {
          return {
            ...flags,
            ...flagsPatch,
          };
        });
      }
    },
    [crmData]
  );

  const refreshData = useCallback(async () => {
    const accessToken = await getAccessToken();
    if (!crmData && accessToken) {
      loadCrmData(accessToken, setCrmData);
    } else if (crmData?.UserId && !userAgentFlags) {
      loadUserAgentFlags(crmData.UserId, accessToken, setUserAgentFlags);
    }
  }, [crmData, getAccessToken, userAgentFlags]);

  useEffect(() => {
    void refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (crmAgentId && crmData && crmAgentId !== crmData.UserId) {
      getAssignedAgent(crmAgentId);
    }
  }, [crmAgentId, crmData, getAssignedAgent]);

  return {
    crmData,
    agentName,
    userAgentFlags,
    confirmUserAgentFlag,
  };
};
