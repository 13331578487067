import { useCallback } from 'react';
import { getDynamicsOpportunityDetails } from '@/services/opportunity/network/dynamics';
import { postEav } from '@/services/opportunity/network/eav';
import { generateEAVItem } from '@/services/opportunity/utils';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { AgentIntakeForm } from '@/services/forms/agent/schema';
import {
  errorIsAuthError,
  useAccessToken,
} from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

const selectFields = 'name,_bw_agent_value,_bw_agencyid_value';
const expandFields = '';

const useDynamicsCrmAgentId = () => {
  const authedFetch = useAuthenticatedFetch();

  const { getAccessToken } = useAccessToken();

  const getDynamicsCrmAgentId = useCallback(
    async (
      opportunityId: string,
      entityId: string,
      initFormData: AgentIntakeForm
    ) => {
      let formDataWithCrmInfo = { ...initFormData };

      try {
        const accessToken = await getAccessToken();

        if (!accessToken) {
          throw new Error('Failed to get access token');
        }

        const result = await getDynamicsOpportunityDetails({
          accessToken,
          opportunityId,
          queryParams: { selectFields, expandFields },
        });

        const existingCrmAgentId = initFormData.crm?.agentId;

        const dynamicsCrmAgentId =
          result && result._bw_agent_value ? result._bw_agent_value : undefined;

        const dynamicsCrmAgencyId =
          result && result._bw_agencyid_value
            ? result._bw_agencyid_value
            : undefined;

        if (
          (!dynamicsCrmAgentId && !existingCrmAgentId) ||
          existingCrmAgentId === dynamicsCrmAgentId
        ) {
          return formDataWithCrmInfo;
        }

        formDataWithCrmInfo = {
          ...initFormData,
          crm: {
            ...initFormData.crm,
            agentId: dynamicsCrmAgentId,
            agencyId: dynamicsCrmAgencyId,
          },
        };

        const crmEav = [
          generateEAVItem(entityId, 'crm.agentId', dynamicsCrmAgentId),
          generateEAVItem(entityId, 'crm.agencyId', dynamicsCrmAgencyId),
        ];

        const eavResult = await postEav({ body: crmEav, fetchFn: authedFetch });

        if (!eavResult) {
          clientLogger.error('Failed to persist crm EAV');
        }
      } catch (error) {
        if (!errorIsAuthError(error)) {
          // Assume auth errors have already been logged
          clientLogger.error('Failed to load crmAgentId from Dynamics', error);
        }
        return formDataWithCrmInfo;
      }

      return formDataWithCrmInfo;
    },
    [authedFetch, getAccessToken]
  );

  return { getDynamicsCrmAgentId };
};

export { useDynamicsCrmAgentId };
