import { useCallback, useEffect, useState } from 'react';
import { getRaterIntegration } from '@/services/opportunity/network/dynamics';
import {
  insuranceProductFromCrmMapping,
  mapRaterIntegrationFromRecordSet,
  populateProductsForRater,
} from '@/services/opportunity/utils';
import {
  RaterIntegration,
  RaterIntegrationResponse,
} from '../../../models/opportunity/raterIntegration';
import { RaterProduct } from '../../../models/opportunity/raterProductTypes';
import { InsuranceProduct } from '@bwinsurance/meta-rater-types';
import {
  errorIsAuthError,
  useAccessToken,
} from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

export const useRaterIntegration = ({
  loading,
  crmAgentId,
}: {
  loading: boolean;
  crmAgentId?: string;
}) => {
  const [raterProductEnabled, setRaterProductEnabled] = useState<
    RaterProduct[]
  >([]);

  const { getAccessToken } = useAccessToken();

  const loadRaterIntegration = useCallback(
    async (agentId: string) => {
      try {
        const accessToken = await getAccessToken();

        if (!accessToken) {
          throw new Error('Failed to get access token');
        }
        const result: RaterIntegrationResponse[] | null =
          await getRaterIntegration(agentId, accessToken);
        const raterIntegrations: RaterIntegration[] = (result || []).map(
          mapRaterIntegrationFromRecordSet
        );

        const newRaterProductEnabled = raterIntegrations.map((item) => {
          return {
            rater: item.rater,
            product: insuranceProductFromCrmMapping[
              item.product
            ] as InsuranceProduct,
          };
        });

        const updatedRaterProducts = populateProductsForRater(
          newRaterProductEnabled
        );

        setRaterProductEnabled(updatedRaterProducts);
      } catch (error) {
        if (!errorIsAuthError(error)) {
          clientLogger.log('Failed to load rater integration data', error);
        }
        setRaterProductEnabled([]);
      }
    },
    [getAccessToken]
  );

  useEffect(() => {
    if (!loading && crmAgentId) {
      loadRaterIntegration(crmAgentId).catch((cause: unknown) =>
        clientLogger.error(
          'An error occurred while attempting to `loadRaterIntegration`.',
          cause
        )
      );
    }
  }, [loading, crmAgentId, loadRaterIntegration]);

  return { raterProductEnabled };
};
