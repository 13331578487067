import {
  BrowserCacheLocation,
  Configuration,
  RedirectRequest,
} from '@azure/msal-browser';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let bwConfig: any = {};

if ('BWGLOBALCONFIG' in window) {
   
  // @ts-ignore
  bwConfig = JSON.parse(decodeURI(window.BWGLOBALCONFIG));
}

export const msalConfig: Configuration = {
  auth: {
    clientId: bwConfig.entraClientId || '', // DocumentUI-Dev Entra App
    authority: bwConfig.entraAuthority || '',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

export const dataverse = {
  baseApi: bwConfig.dataverseBaseApi || '',
  baseUrl: bwConfig.dataverseBaseUrl || '',
  resource: bwConfig.dataverseResource || '',
};

export const dataverseLoginRequest: RedirectRequest = {
  scopes: [`${dataverse.resource}/user_impersonation`],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const graphLoginRequest: RedirectRequest = {
  scopes: ['User.Read'],
};

export const loginRequest = dataverseLoginRequest;

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const mapsApiKey = bwConfig.mapsApiKey;

export const featureFlagCompleteForm = bwConfig.featureFlagCompleteForm;
export const featureFlagViewSubmission = bwConfig.featureFlagViewSubmission;
export const isPrefillIntegrationEnabled = bwConfig.isPrefillIntegrationEnabled;

export const documentConfig = {
  timeout: process.env.DOCUMENT_REQUEST_TIMEOUT || 10000, // 10 sec timeout
  maxEmailSize: process.env.MAX_EMAIL_SIZE || '29000000', // 29MB attachments size cap
  autoPullPollInterval: process.env.AUTO_PULL_POLL_INTERVAL || '5000', //5 sec timeout
};

export const formsConfig = {
  underwritingAllowedEmails: bwConfig.underwritingAllowedEmails,
};

export const maxUploadSize = process.env.MAX_UPLOAD_SIZE || '29000000';
