import { useEffect, useState, type FC } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { FormField } from '@/services/forms/models/formTypes';
import { useFormState, get, FieldError, useFormContext } from 'react-hook-form';
import { VinDialog } from '../VinDialog';
import { usePopulateAutoDetails } from './usePopulateAutoDetails';

export type VinInputFieldProps = {
  field: FormField;
};

const VinField: FC<VinInputFieldProps> = ({ field }) => {
  const { getValues, register, setValue, trigger, watch } = useFormContext();
  const { id, name, display, placeholder, syncWith, disabled, highlight } =
    field;
  const { errors, isValid } = useFormState();
  const error: FieldError = get(errors, name);
  const val = watch(name);

  type SubModelVehicle = {
    vin: string | undefined;
    vehicle_number: number | undefined;
  };

  const [subModelOpen, setSubModelOpen] = useState<boolean>(false);
  const [subModelVehicle, setSubModelVehicle] = useState<
    SubModelVehicle | undefined
  >({
    vin: '',
    vehicle_number: 0,
  });

  useEffect(() => {
    if (!isValid && field.validation?.required) {
      trigger(name).catch((cause: unknown) =>
        console.error(
          'An error occurred while triggering name validation.',
          cause
        )
      );
    }
  }, [val]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value || undefined, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (syncWith) {
      setValue(syncWith.fieldName, e.target.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const getVehicleNumber = (fieldName: string) =>
    parseInt(fieldName.split('.')[1]);

  const getVehicleData = (vehicleNumber: number) => {
    const vehicles = getValues('vehicles');
    return vehicles?.[vehicleNumber];
  };

  const handleSubModal = () => {
    const vehicleNumber = getVehicleNumber(field.name);
    const vehicleData = getVehicleData(vehicleNumber);

    if (!vehicleData) return;

    const { vin } = vehicleData;

    if (!vin) return;

    setSubModelVehicle({
      vin,
      vehicle_number: vehicleNumber,
    });

    setSubModelOpen(true);
  };

  const { populateAutoDetails } = usePopulateAutoDetails();

  const assignVehicleData = (
    vehicle_number: number,
    year: string,
    make: string,
    model: string,
    subModel: string
  ) => {
    populateAutoDetails({ vehicle_number, year, make, model, subModel });
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <VinDialog
        open={subModelOpen}
        setOpen={setSubModelOpen}
        subModelVehicle={subModelVehicle}
        setSubModelVehicle={setSubModelVehicle}
        assignVehicleData={assignVehicleData}
      />
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        {...register(name, {
          ...(field.validation ? { ...field.validation } : {}),
        })}
        type={'text'}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        disabled={disabled}
        onChange={handleChange}
      />
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
      <Button
        onClick={handleSubModal}
        className="bg-transparent border-none text-light-text-link underline cursor-pointer flex justify-start p-0"
        type={'button'}
      >
        Populate other fields...
      </Button>
    </div>
  );
};

export { VinField };
