import clientLogger from '@/controllers/logger';
import { waitUntilDate } from '@/services/utils/time';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useToast } from '@bwinsurance/shared-components';
import React, { useCallback } from 'react';
import { AccessTokenContext } from './context';
import { fetchAuthenticationResult } from './AccessTokenProvider.helpers';

/**
 * Provides a context for managing access tokens using MSAL authentication.
 *
 * This component wraps its children with an `AccessTokenContext.Provider` and
 * supplies a `getAccessToken` function to obtain an access token for authenticated
 * API requests.
 *
 * The provider handles token acquisition and renewal using MSAL's `acquireTokenSilent`
 * and `acquireTokenPopup` methods, ensuring the token is refreshed before it expires.
 *
 * It maintains any in-progress authentication requests to prevent duplicate calls.
 * If no active account is found, it throws an error indicating to verify user login.
 *
 * @param children - The child components that require access to the authentication context.
 */
const AccessTokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [ToastDialogList, dispatchToast] = useToast();
  const { instance } = useMsal();

  // Store the current in-progress authentication request
  const inProgressAuthFetch = React.useRef<Promise<string> | null>(null);

  // Clear the inProgressAuthFetch when the token expires
  const prepareExpirationClear = useCallback(
    async ({ refreshOn, expiresOn, extExpiresOn }: AuthenticationResult) => {
      // Find the earliest expiration in priority order
      const expiration = refreshOn || expiresOn || extExpiresOn;
      if (expiration) {
        // When the token expires, clear the inProgressAuthFetch
        await waitUntilDate(expiration);
        inProgressAuthFetch.current = null;
        return;
      }
      // If neither is available, do nothing (The token may not expire)
      clientLogger.debug('No expiration trigger found for auth token');
    },
    []
  );

  // Fetch a new authentication result
  const refreshAuthToken = useCallback(async (): Promise<string> => {
    // This may throw an error
    const authResult = await fetchAuthenticationResult({
      msalInstance: instance,
      dispatchErrorMessage: (message) =>
        dispatchToast({ type: 'error', children: message }),
    });

    // Clear the auth after it expires
    void prepareExpirationClear(authResult);

    // Return the access token
    return authResult.accessToken;
  }, [dispatchToast, instance, prepareExpirationClear]);

  // Get the access token
  const getAccessToken = useCallback(async () => {
    // Check for pre-existing auth fetches
    if (!inProgressAuthFetch.current) {
      // If no in-progress request, make a new one
      inProgressAuthFetch.current = refreshAuthToken();
    }

    // And return the promise
    return inProgressAuthFetch.current;
  }, [refreshAuthToken]);

  return (
    <AccessTokenContext.Provider value={{ getAccessToken }}>
      {children}
      {/* Render Toasts if an error occurs */}
      {ToastDialogList}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenProvider;
