import { BrowserAuthError } from '@azure/msal-browser';

/**
 * Checks if an error is a BrowserAuthError.
 *
 * @param error - The error to be checked.
 * @returns True if the error is a BrowserAuthError, false otherwise.
 */
export const errorIsAuthError = (error: unknown): error is BrowserAuthError => {
  return error instanceof BrowserAuthError;
};

/**
 * Rethrows any non-BrowserAuthError that is passed in.
 *
 * @throws error - The error to be rethrown if it is not a BrowserAuthError.
 */
export const rethrowNonAuthError = (error: unknown) => {
  // The error returned from a failed msal auth call is a BrowserAuthError
  // We will assume the error has already been logged at some point, so we don't want to log it again
  if (!errorIsAuthError(error)) {
    // If this isn't a BrowserAuthError, rethrow
    throw error;
  }
};
