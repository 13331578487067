import clientLogger from '@/controllers/logger';
import { createContext } from 'react';

export interface AccessTokenContextValue {
  getAccessToken: () => Promise<string>;
}

const CONTEXT_ERROR = 'AccessTokenContext not initialized';

export const AccessTokenContext = createContext<AccessTokenContextValue>({
  getAccessToken: () => {
    clientLogger.error(CONTEXT_ERROR);
    return Promise.reject(CONTEXT_ERROR);
  },
});
