import type { FC } from 'react';
import type { FormPanel, FormAction } from '@/services/forms/models/formTypes';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Section from '../../Section';
import SubheadingDivider from '../../SubheadingDivider';
import FieldMapper from '../FieldMapper';
import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import ActionMapper from '../Actions';
import { SearchResultField} from '@/services/opportunity/utils/formSearch';

type DynamicPanelProps = {
  panel: FormPanel;
  panelDefaults: Record<string, unknown>;
  searchResults?: SearchResultField[] | null;
  formMethods?: UseFormReturn<AgentIntakeForm>;
  handlePrefill?: ({
    prefillTarget,
    dynamicFieldIndex,
  }: {
    prefillTarget: string;
    dynamicFieldIndex?: number;
  }) => void;
};

const DynamicPanel: FC<DynamicPanelProps> = ({
  panel,
  panelDefaults,
  searchResults,
  formMethods,
  handlePrefill,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: panel.id,
    control,
  });

  const handleClickAppend = () => {
    append(panelDefaults);
  };

  // TODO: Move this functionality out of our generic render function into hooks
  // trigger these functionality with a flag from the JSON
  const removeVehicle = (vehicleNumber: number) => {
    formMethods?.setValue(`vehicles.${vehicleNumber}.subModel`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.year`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.make`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.model`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.vin`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(
      `vehicles.${vehicleNumber}.usage.principalDriverId`,
      '',
      {
        shouldDirty: true,
      }
    );
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.usage`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.oneWayMiles`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.daysPerWeek`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.weeksPerMonth`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.annualMiles`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.ownership`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.usage.odometer`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`vehicles.${vehicleNumber}.isDeleted`, true, {
      shouldDirty: true,
    });
  };

  const removeDriver = (driverNumber: number) => {
    formMethods?.setValue(`drivers.${driverNumber}.name.firstName`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.name.lastName`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.gender`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.dateOfBirth`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.maritalStatus`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.relation`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.education`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.employmentStatus`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.industry`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.occupation`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.license.licenseNumber`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.license.state`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.license.licensedDate`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.license.status`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(`drivers.${driverNumber}.hasReceivedViolation`, '', {
      shouldDirty: true,
    });
    formMethods?.setValue(
      `drivers.${driverNumber}.hasAccidentWithinLastFiveYears`,
      '',
      {
        shouldDirty: true,
      }
    );
    formMethods?.setValue(
      `drivers.${driverNumber}.hasLicenseRevokedSuspended`,
      '',
      {
        shouldDirty: true,
      }
    );
    formMethods?.setValue(`drivers.${driverNumber}.isDeleted`, true, {
      shouldDirty: true,
    });
  };
  let hiddenCount = 0;

  const getDynamicPanelActions = ({
    actions,
    sectionHeading,
    index,
  }: {
    actions?: FormAction[];
    sectionHeading: string;
    index: number;
  }) => {
    if (!actions) {
      return null;
    }

    return (
      <div
        data-testid={`${sectionHeading}-field-sync-container`}
        className="flex flex-row gap-2"
      >
        {actions.map((action) => {
          if (
            !action.syncFieldsOptions ||
            action.syncFieldsOptions.elementName !== sectionHeading
          ) {
            return null;
          }

          return <ActionMapper key={action.id} action={action} index={index} />;
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-8 -ml-8">
      {fields.map((field, fieldIdx) => {
        // TODO: Move this to JSON to keep component reusable update loop to be reusable
        const panels = ['vehicles', 'drivers'];
        for (const id of panels) {
          if (panel.id === id) {
            const isDeleted = formMethods?.watch(
              `${
                id === 'vehicles' ? 'vehicles' : 'drivers'
              }.${fieldIdx}.isDeleted`
            );
            if (isDeleted) {
              hiddenCount++;
              return null;
            }
          }
        }

        const headingText = `${panel.display} ${fieldIdx + 1 - hiddenCount}`;

        return (
          <Section
            key={field.id}
            headingText={headingText}
            materialIcon={panel.icon}
            actions={getDynamicPanelActions({
              actions: panel.actions,
              sectionHeading: headingText,
              index: fieldIdx,
            })}
          >
            <div>
              <section
                className="flex flex-col gap-8"
                key={`${field.id}::${field.id}`}
              >
                {panel.elements.map((section, idx) => {
                  if (section.type === 'section') {
                    return (
                      <SubheadingDivider
                        headingText={section.display}
                        key={`${field.id}::${section.id}`}
                        headingActions={section.actions && {
                          actions: section.actions,
                          index: fieldIdx,
                          handlePrefill: handlePrefill,
                        }}
                      >
                        {section.elements.map((inputField, i) => {
                          const dynamicName = `${panel.id}.${fieldIdx}.${inputField.name}`;
                          const dynamicVisibleIf = inputField.visibleIf
                            ? `${panel.id}.${fieldIdx}.${inputField.visibleIf.fieldName}`
                            : null;
                          const dynamicField = {
                            ...inputField,
                            name: dynamicName,
                            ...(dynamicVisibleIf
                              ? {
                                  visibleIf: {
                                    ...inputField.visibleIf,
                                    fieldName: dynamicVisibleIf,
                                  },
                                }
                              : {}),
                            ...(inputField.syncWith && fieldIdx === 0
                              ? {
                                  syncWith: {
                                    ...inputField.syncWith,
                                  },
                                }
                              : { syncWith: undefined }),
                          };

                          dynamicField.highlight =
                            searchResults?.some(
                              (result: SearchResultField) =>
                                dynamicField.display === result.display
                            ) || null;

                          return (
                            <FieldMapper
                              key={`${field.id}::${inputField.name}`}
                              field={dynamicField}
                            />
                          );
                        })}
                        {idx + 1 === panel.elements.length &&
                          fieldIdx !== 0 && (
                            <button
                              className="flex pb-[4px] gap-1.25 items-end text-light-text-error"
                              type="button"
                              onClick={() => {
                                const removeActions = {
                                  Vehicle: removeVehicle,
                                  Driver: removeDriver,
                                } as const;

                                (
                                  removeActions[
                                    panel.display as keyof typeof removeActions
                                  ] || remove
                                )(fieldIdx);
                              }}
                            >
                              <span className="material-symbols-outlined font18 text-light-text-error dark:text-dark-text-error">
                                {'delete'}
                              </span>
                              <span>Delete</span>
                            </button>
                          )}
                      </SubheadingDivider>
                    );
                  }
                  // TODO: When needed, add dynamic sections here
                  return null;
                })}
              </section>
            </div>
          </Section>
        );
      })}
      <button
        className="flex items-center gap-1.25 pl-8 w-fit"
        type="button"
        onClick={handleClickAppend}
      >
        <span className="material-symbols-outlined font18 text-light-text-secondary dark:text-dark-text-secondary">
          {'add'}
        </span>
        <span>{`Add ${panel.display}`}</span>
      </button>
    </div>
  );
};

export { DynamicPanel };
