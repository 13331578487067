import { FC, useEffect, useMemo, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Document } from '@/models/document/document';
import styles from './index.module.css';
import UploadOverlay from '@/components/documents/uploadOverlay';
import Upload from '@/components/documents/dialogs/upload';
import { useMsal } from '@azure/msal-react';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '@/components/shared/alertToast';
import DocumentTable from '@/components/documents/DocumentTable/documentTable';
import ControlBar from '@/components/documents/controlBar';
import { useLocation } from 'react-router-dom';
import { validateFile } from '@/controllers/document/utils';

import {
  accountIdAtom,
  categoryListAtom,
  documentsListAtom,
  selectedDocumentsListAtom,
  opportunityIdAtom,
  tableTextAtom,
  userNameAtom,
} from '@/store/documentsAtoms';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const applySearchFilters = (
  documents: Document[] | undefined,
  search: string
) => {
  if (!search) {
    return documents;
  }
  return documents?.filter((document: Document) => {
    const loweredSearch = search?.toLowerCase();

    return (
      document.createdAt?.includes(loweredSearch) ||
      document.fileOriginalName?.toLowerCase()?.includes(loweredSearch) ||
      document.documentType?.toLowerCase()?.includes(loweredSearch) ||
      document.createdBy?.name?.toLowerCase()?.includes(loweredSearch) ||
      document.notes?.toLowerCase()?.includes(loweredSearch) ||
      document.id.toLowerCase().includes(loweredSearch)
    );
  });
};

export type DocumentsForOpportunityProps = {
  refreshData: () => void;
};

const DocumentsForOpportunity: FC<DocumentsForOpportunityProps> = ({
  refreshData,
}) => {
  const queryParams = useQuery();
  const { instance } = useMsal();
  const [uploadOverlayOpen, setUploadOverlayOpen] = useState<boolean>(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<any>();

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>();
  const [search, setSearch] = useState<string>(queryParams.get('search') || '');

  const setSelectedDocuments = useSetAtom(selectedDocumentsListAtom);

  const [alertToastOpen, setAlertToastOpen] = useState<boolean>(false);
  const [alertToast, setAlertToast] = useState<AlertToastInterface>({
    text: '',
    type: 'info',
  });

  const documentsList = useAtomValue(documentsListAtom);
  const userName = useAtomValue(userNameAtom);
  const categoryList = useAtomValue(categoryListAtom);
  const tableText = useAtomValue(tableTextAtom);
  const opportunityId = useAtomValue(opportunityIdAtom);
  const accountId = useAtomValue(accountIdAtom);

  useEffect(() => {
    if (documentsList?.length) {
      setFilteredDocuments(applySearchFilters(documentsList, search));
    }
  }, [search, documentsList]);

  const dropHandler = (event: any) => {
    event.preventDefault();

    const selectedFile = event.dataTransfer.files;
    const { isInvalid, message } = validateFile(selectedFile[0]);
    if (isInvalid) {
      setUploadOverlayOpen(false);
      showAlertToast('warning', message);
      return;
    }
    setUploadFiles(selectedFile);

    showUploadDialog();
  };

  const showUploadDialog = () => {
    setUploadOverlayOpen(false);
    setUploadDialogOpen(true);
  };

  const showAlertToast = (type: string, text: string) => {
    setAlertToast({
      type,
      text,
    });

    setAlertToastOpen(!!text);
  };

  useEffect(() => {
    if (documentsList) {
      setSelectedDocuments(
        documentsList.filter((document: Document) => !!document.selected)
      );
    }
  }, [documentsList]);

  return (
    <div
      className={`${styles.documents}`}
      onDrop={dropHandler}
      onDragOver={(event: any) => {
        if (!uploadDialogOpen) {
          event.preventDefault();
          setUploadOverlayOpen(true);
        }
      }}
    >
      <ControlBar
        policyList={[]}
        showAutoPullMessage={null}
        search={search}
        setSearch={setSearch}
        setUploadFiles={setUploadFiles}
        showUploadDialog={showUploadDialog}
        autoPullDisabled={true}
        refreshData={refreshData}
        showAlertToast={showAlertToast}
        forOppDocsView={true}
      />
      <DocumentTable
        documents={filteredDocuments}
        setSelectedDocuments={setSelectedDocuments}
        tableText={tableText}
        showAlertToast={showAlertToast}
        options={{
          columns: {
            link: {
              hide: true,
            },
          },
        }}
      />
      <Upload
        open={uploadDialogOpen}
        closeDialog={() => {
          setUploadDialogOpen(false);
        }}
        files={uploadFiles}
        categoryList={categoryList}
        policyList={[]}
        opportunityList={[]}
        accountId={accountId}
        showAlertToast={showAlertToast}
        refreshData={refreshData}
        pca={instance}
        userName={userName}
        linkedOppId={opportunityId}
      />
      <UploadOverlay
        open={uploadOverlayOpen}
        onClose={(event: any) => {
          event.preventDefault();
          setUploadOverlayOpen(false);
        }}
      />
      <AlertToast
        open={alertToastOpen}
        closeAlert={() => {
          setAlertToastOpen(false);
        }}
        type={alertToast.type}
        text={alertToast.text}
      />
    </div>
  );
};

export default DocumentsForOpportunity;
