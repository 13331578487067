import { useCallback } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';
import { populateAuto } from '@/services/forms/prefill/populateAuto';
import { populateProperty } from '@/services/forms/prefill/populateProperty';
import { populateDriversLicense } from '@/services/forms/prefill/populateDriversLicense';
import { AgentIntakeForm } from '@/services/forms/agent/schema';

const usePopulatePrefillData = ({
  formMethods,
}: {
  formMethods: UseFormReturn<AgentIntakeForm>;
}) => {
  const driverFieldArray = useFieldArray({
    name: 'drivers',
    control: formMethods.control,
  });
  const vehicleFieldArray = useFieldArray({
    name: 'vehicles',
    control: formMethods.control,
  });

  const populatePrefillData = useCallback(
    ({
      prefillTarget,
      data,
      fieldIndex,
    }: {
      prefillTarget: string;
      data: PrefillResponse | null;
      fieldIndex?: number;
    }) => {
      if (prefillTarget === 'home') {
        populateProperty(formMethods, data);
      } else if (prefillTarget === 'auto') {
        populateAuto(formMethods, data, driverFieldArray, vehicleFieldArray);
      } else if (prefillTarget === 'driversLicense') {
        populateDriversLicense(formMethods, data, fieldIndex);
      }
    },
    [driverFieldArray, formMethods, vehicleFieldArray]
  );
  return { populatePrefillData };
};

export { usePopulatePrefillData };
