import { useCallback } from 'react';
import { useAccessToken } from '@/components/context/AccessTokenContext';

export const useAuthenticatedFetch = () => {
  const { getAccessToken } = useAccessToken();

  const getAuthenticatedFetchFn = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
      const accessToken = await getAccessToken();
      const authHeaders = {
        Authorization: `Bearer ${accessToken}`,
      };

      const initWithAuth = {
        ...init,
        headers: {
          ...init?.headers,
          ...authHeaders,
        },
      };

      return fetch(input, initWithAuth);
    },
    [getAccessToken]
  );

  return getAuthenticatedFetchFn;
};
