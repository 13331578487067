import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getCustomerData,
  getIntakeData,
  CustomerData,
} from '@/controllers/common/intake';
import ApplicantDisplay from '@/components/Applicant/eav';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { IntakeNavOptions } from '@/services/common/constants';
import IntakeLeftNav from '@/components/intake/IntakeLeftNav';
import Switch from '@/components/Switch';
import IntakeHeader from '@/components/intake/IntakeHeader/eav';
import HomeInfo from '@/components/HomeInfo/eav';
import AutoInfo from '@/components/AutoInfo/eav';
import PriorPolicyInfo from '@/components/PriorPolicyInfo/eav';
import { useTitle } from '@/controllers/common/hooks/useTitle';
import { EAV } from '@bwinsurance/common-utils';
import { getEav } from '@/services/opportunity/network/eav';
import logger from '@/controllers/logger';
import { errorIsAuthError } from '@/components/context/AccessTokenContext';
import clientLogger from '@/controllers/logger';

const IntakeV2 = () => {
  useTitle('Intake Info - Brightway');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const [eav, setEav] = useState<EAV>();
  const [selectedTab, setSelectedTab] = useState<IntakeNavOptions>(
    IntakeNavOptions.APPLICANT
  );
  const [customerData, setCustomerData] = useState<CustomerData>();
  const { id } = useParams();
  const authedFetch = useAuthenticatedFetch();
  const [searchParams] = useSearchParams();
  let entityId = searchParams.get('entityId') ?? '';

  useEffect(() => {
    if (id) {
      const loadData = async (id: string) => {
        setLoading(true);
        try {
          if (!entityId) {
            const intake = await getIntakeData(id, authedFetch);
            if (intake.error || !intake.applicationV2.id) {
              throw new Error('Failed to load intake data');
            } else {
              entityId = intake.applicationV2.id as string;
            }
          }
          const data = await getEav(entityId, authedFetch);
          if (!data) {
            throw new Error('Failed to load opportunity data');
          } else {
            const eav = EAV.from(data);
            setEav(eav);
            const applicantCrmAccountId = eav.getValue('crm.accountId');
            if (typeof applicantCrmAccountId !== 'string') {
              throw new Error('Applicant CRM account ID not found');
            }
            const customerData = await getCustomerData(
              applicantCrmAccountId,
              authedFetch
            );
            setCustomerData(customerData);
          }
        } catch (error) {
          if (!errorIsAuthError(error)) {
            clientLogger.log('Failed to load opportunity data.', error);
          }
          setErrorText('Failed to load opportunity data, please try again.');
        }
        setLoading(false);
      };
      loadData(id).catch((cause: unknown) =>
        logger.error(
          'An error occured while attempting to `loadData` for intake eav.',
          cause
        )
      );
    }
  }, [id, entityId, authedFetch]);

  const showHome = Boolean(eav?.getChildren({ prefix: 'home.' })?.length);
  const showAuto = Boolean(eav?.getChildren({ prefix: 'auto.' })?.length);

  const homePriorPolicy = eav?.getChildren({ prefix: 'home.priorPolicy.' });
  const autoPriorPolicy = eav?.getChildren({ prefix: 'auto.priorPolicy.' });
  const showPriorPolicy = Boolean(
    homePriorPolicy?.length || autoPriorPolicy?.length
  );

  const hiddenNavItems = useMemo(() => {
    const hidden: IntakeNavOptions[] = [];
    if (!showHome) {
      hidden.push(IntakeNavOptions.HOME);
    }
    if (!showAuto) {
      hidden.push(IntakeNavOptions.AUTO);
    }
    if (!showPriorPolicy) {
      hidden.push(IntakeNavOptions.POLICY);
    }
    return hidden;
  }, [showHome, showAuto, showPriorPolicy]);

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <IntakeHeader eav={eav} customerData={customerData} />
      <div className="flex flex-row gap-4 overflow-y-auto">
        <IntakeLeftNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          hiddenItems={hiddenNavItems}
        />
        <div className="w-full">
          <div className="flex flex-col gap-8 ps-22 py-6 pr-6">
            <Switch selectedKey={selectedTab}>
              <ApplicantDisplay
                key={IntakeNavOptions.APPLICANT}
                eav={eav}
                longform
              />
              <HomeInfo key={IntakeNavOptions.HOME} eav={eav} />
              <AutoInfo key={IntakeNavOptions.AUTO} eav={eav} />
              <PriorPolicyInfo key={IntakeNavOptions.POLICY} eav={eav} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntakeV2;
