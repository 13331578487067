import { isPrefillIntegrationEnabled } from '@/services/common/config';
import { IntakeNavOptions } from '@/services/common/constants';
import { UseFormGetValues } from 'react-hook-form';
import { AgentIntakeForm } from '../agent/schema';
import { PrefillResponse } from '../models/prefillTypes';

export const OVERRIDE_PREFILL_KEY = 'overridePrefill';

export const showPrefillBanner = (selectedKey: string): boolean => {
  const isSupportedKey =
    selectedKey === IntakeNavOptions.AUTO ||
    selectedKey === IntakeNavOptions.HOME;
  return isPrefillIntegrationEnabled && isSupportedKey;
};

export const getPrefillType = (selectedKey: string) => {
  const isHomeTab = selectedKey === IntakeNavOptions.HOME;
  const prefillType = isHomeTab ? 'property' : 'driver and vehicle';
  const prefillAddressType = isHomeTab ? 'property' : 'applicant';
  return { isHomeTab, prefillType, prefillAddressType };
};

export const checkRequiredFieldsForPrefill = ({
  prefillTarget,
  getValues,
  fieldIdx = 0,
}: {
  prefillTarget: string;
  getValues: UseFormGetValues<AgentIntakeForm>;
  fieldIdx?: number;
}) => {
  const baseRequiredAddressFields = {
    'applicant.currentAddress.lineOne': 'lineOne',
    'applicant.currentAddress.city': 'city',
    'applicant.currentAddress.stateCode': 'stateCode',
    'applicant.currentAddress.postalCode': 'postalCode',
  };

  const requiredAutoFields = {
    ...baseRequiredAddressFields,
    'applicant.firstName': 'firstName',
    'applicant.lastName': 'lastName',
  };
  const requiredPropertyFields = {
    'home.address.lineOne': 'lineOne',
    'home.address.stateCode': 'stateCode',
  };

  const requiredDriversLicenseFields = {
    ...baseRequiredAddressFields,
    [`drivers.${fieldIdx}.name.firstName`]: 'firstName',
    [`drivers.${fieldIdx}.name.lastName`]: 'lastName',
    [`drivers.${fieldIdx}.dateOfBirth`]: 'dateOfBirth',
  };

  const requiredFields =
    prefillTarget === 'driversLicense'
      ? requiredDriversLicenseFields
      : prefillTarget === 'home'
      ? requiredPropertyFields
      : requiredAutoFields;
  const missingFields: string[] = [];
  for (const [key, value] of Object.entries(requiredFields)) {
    const fieldValue = getValues(key as keyof AgentIntakeForm);
    if (!fieldValue || (typeof fieldValue === 'string' && !fieldValue.trim())) {
      missingFields.push(value);
    }
  }
  return missingFields;
};

export const getMissingFieldsString = ({
  prefillTarget,
  missingFields,
}: {
  prefillTarget: string;
  missingFields: string[];
}) => {
  if (prefillTarget === 'home') {
    return "property's address";
  }

  const hasMissing = (fields: string[]) =>
    missingFields.some((field) => fields.includes(field));

  const isDL = prefillTarget === 'driversLicense';
  const missingSubject = isDL ? "driver's" : "applicant's";

  const nameFields = ['firstName', 'lastName'];
  const dateOfBirthField = ['dateOfBirth'];
  const addressFields = ['lineOne', 'city', 'stateCode', 'postalCode'];

  const missingParts: string[] = [];
  if (hasMissing(nameFields)) {
    missingParts.push(`${missingSubject} name`);
  }
  if (isDL && hasMissing(dateOfBirthField)) {
    missingParts.push("driver's date of birth");
  }
  if (hasMissing(addressFields)) {
    missingParts.push("applicant's current address");
  }

  return missingParts.join(' and ');
};

export const isPrefillButtonDisabled = (
  isHomeTab: boolean,
  formData: AgentIntakeForm | undefined
) => {
  const overridePrefill = localStorage.getItem(OVERRIDE_PREFILL_KEY);

  // if override flag exists in localStorage
  // do not disable prefill button
  if (overridePrefill) {
    return false;
  }

  return isHomeTab
    ? !!formData?.prefillStatus?.property
    : !!formData?.prefillStatus?.auto;
};

export const getPrefillToast = ({
  result,
  prefillType,
  prefillTarget,
}: {
  result: PrefillResponse;
  prefillType: string;
  prefillTarget: string;
}) => {
  const failedResponses = result.responses.filter(
    (response) => response.status === 'FAILURE'
  );
  if (failedResponses.length) {
    const badRequest = failedResponses.find(
      (response) => response.error?.name === 'BadRequestError'
    );

    if (badRequest) {
      return {
        type: 'warning',
        text: badRequest.error?.message as string,
      };
    }

    throw new Error('Failed to prefill data');
  }

  const noDataReturned = result?.responses.every(
    (response) => !response.data || !Object.keys(response.data).length
  );

  const subjectPrefilled =
    prefillTarget === 'driversLicense' ? 'drivers license' : prefillType;

  return noDataReturned
    ? {
        type: 'info',
        text: `No pre-fill data found for ${subjectPrefilled}.`,
      }
    : {
        type: 'success',
        text: `We've pre-filled data for ${subjectPrefilled}.`,
      };
};
