/**
 * Waits until a specific date is reached before resolving.
 *
 *
 * @param date The date to wait until
 */
export const waitUntilDate = async (date: Date): Promise<void> => {
  const now = new Date().getTime();
  const targetTime = date.getTime();
  const timeDiff = targetTime - now;

  if (timeDiff < 0) {
    return;
  }

  return new Promise((resolve) => setTimeout(resolve, timeDiff));
};
