import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { useEffect, useMemo, useState } from 'react';
import { getQuoteSubmissionsForApplication } from '@/services/opportunity/network/quote';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import {
  findRaterQuoteGroup,
  getSuccessRaterQuoteGroups,
} from '@/services/forms/helpers/utils';
import { RatersEnum } from '@bwinsurance/meta-rater-types';
import logger from '@/controllers/logger';
import { RaterProduct } from '@/models/opportunity/raterProductTypes';

export const useQuoteSubmissionForApplication = ({
  loading,
  applicationId,
  raterProductEnabled,
}: {
  loading: boolean;
  applicationId?: string;
  raterProductEnabled: RaterProduct[];
}) => {
  const authedFetch = useAuthenticatedFetch();

  const [successQuoteGroups, setSuccessQuoteGroups] = useState<
    QuoteGroupWithQuote[]
  >([]);
  const [allQuoteGroups, setAllQuoteGroups] = useState<QuoteGroupWithQuote[]>([]);

  const successQRQuoteGroup = useMemo(() => {
    return findRaterQuoteGroup(successQuoteGroups, RatersEnum.enum.QuoteRush);
  }, [successQuoteGroups]);

  const loadQuoteSubmission = async (appId: string) => {
    const quoteSubmissions = await getQuoteSubmissionsForApplication({
      applicationId: appId,
      fetchFn: authedFetch,
    });
    const quoteGroups: QuoteGroupWithQuote[] = [];
    (quoteSubmissions || []).forEach((quoteSubmission) => {
      quoteGroups.push(...quoteSubmission.quoteGroups);
    });
    setAllQuoteGroups(quoteGroups);
  };

  const updateSuccessQuoteGroups = (quoteGroups: QuoteGroupWithQuote[]) => {
    const successRaterQuoteGroups = getSuccessRaterQuoteGroups(
      quoteGroups,
      raterProductEnabled
    );
    setSuccessQuoteGroups(successRaterQuoteGroups);
  };

  useEffect(() => {
    updateSuccessQuoteGroups(allQuoteGroups);
  }, [allQuoteGroups.length, raterProductEnabled.length]);

  useEffect(() => {
    if (!loading && applicationId) {
      loadQuoteSubmission(applicationId).catch((cause: unknown) =>
        logger.error(
          'An error occured while attempting to `loadQuoteSubmission`.',
          cause
        )
      );
    }
  }, [loading, applicationId]);

  return { successQuoteGroups, updateSuccessQuoteGroups, successQRQuoteGroup };
};
