import {
  IPublicClientApplication,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PropsWithChildren } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Document from './pages/documents';
import Intake from './pages/intake';
import IntakeV2 from './pages/intake/eav';
import 'material-symbols/outlined.css';
import './global.css';

import Header from './components/Header';
import AppRoutes from './routes';

import {
  featureFlagCompleteForm,
  featureFlagViewSubmission,
  loginRequest,
} from './services/common/config';
import OpportunityIntake from './pages/opportunity/intake';
import Forms from './pages/forms';
import Form from './pages/form';
import Submission from './pages/submission';
import { AccessTokenProvider } from './components/context/AccessTokenContext';
import clientLogger from './controllers/logger';

function App({ pca }: { pca: IPublicClientApplication }) {
  return (
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <Auth>
          <AccessTokenProvider>
            <Routes>
              <Route
                path="/account/:id"
                element={
                  <EmbedLayout>
                    <Document pca={pca} />
                  </EmbedLayout>
                }
              />
              <Route
                path="/account/:id/forms"
                element={
                  <EmbedLayout>
                    <Forms pca={pca} />
                  </EmbedLayout>
                }
              />
              {featureFlagCompleteForm && (
                <Route
                  path="/account/:id/forms/:formKey"
                  element={
                    <EmbedLayout>
                      <Form pca={pca} />
                    </EmbedLayout>
                  }
                />
              )}
              {featureFlagViewSubmission && (
                <Route
                  path="/submission/:id"
                  element={
                    <EmbedLayout>
                      <Submission pca={pca} />
                    </EmbedLayout>
                  }
                />
              )}
              <Route
                path="/intake/v2/:id"
                element={
                  <EmbedLayout>
                    <IntakeV2 />
                  </EmbedLayout>
                }
              />
              <Route
                path="/intake/:id"
                element={
                  <EmbedLayout>
                    <Intake />
                  </EmbedLayout>
                }
              />
              {/* keeping the old routes until updated in dynamics */}
              <Route
                path="/opportunity/:id/new"
                element={
                  <EmbedLayout>
                    <OpportunityIntake />
                  </EmbedLayout>
                }
              />
              <Route
                path="/embed/*"
                element={
                  <EmbedLayout>
                    <AppRoutes />
                  </EmbedLayout>
                }
              />
              <Route path="/*" element={<DefaultLayout />} />
            </Routes>
          </AccessTokenProvider>
        </Auth>
      </MsalProvider>
    </BrowserRouter>
  );
}

const EmbedLayout = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};

const DefaultLayout = () => {
  return (
    <div className="flex flex-col justify-between w-full h-full min-h-screen">
      <Header />
      <main className="flex-auto w-full max-w-6xl px-4 py-4 mx-auto sm:px-6 md:py-6">
        <AppRoutes />
      </main>
    </div>
  );
};

export default App;

const Auth = ({ children }: PropsWithChildren) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return children;
  }

  const handleSignIn = () => {
    void instance
      .loginPopup(loginRequest)
      .catch((cause: unknown) =>
        clientLogger.error(
          'An error occured while opening the login popup',
          cause
        )
      );
  };

  if (
    inProgress === InteractionStatus.HandleRedirect ||
    inProgress === InteractionStatus.Startup
  ) {
    return <p>loading...</p>;
  }

  // todo: auto sign in but don't get stuck in a loop if sign in fails
  // handleSignIn();
  return (
    <div className="flex flex-col items-center justify-center gap-6 m-6">
      <br />
      <p>Sign-in with your Brightway Credentials</p>
      <br />
      <button
        className="border rounded py-2 px-7 bg-primary text-white"
        onClick={handleSignIn}
      >
        Sign in
      </button>
    </div>
  );
};
