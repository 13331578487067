import { ReactNode } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import { isReactNodeEmpty } from '@/services/lib/react';
import { FormAction } from '@/services/forms/models/formTypes';
import ActionMapper from '../forms/Actions';

interface Props {
  headingText?: string;
  headingActions?: {
    actions: FormAction[];
    index?: number;
    handlePrefill?: ({
      prefillTarget,
      dynamicFieldIndex,
    }: {
      prefillTarget: string;
      dynamicFieldIndex?: number;
    }) => void;
  };
  children: ReactNode;
}

const SubheadingDivider: React.FC<Props> = ({
  headingText,
  headingActions,
  children,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center">
        <span className="text-light-text-primary dark:text-dark-text-primary text-sm">
          {headingText ?? ''}
        </span>
        <div className="min-w-fit mr-2 -ml-1">
          {headingActions?.actions.map((action) => (
            <ActionMapper
              key={action.id}
              action={action}
              index={headingActions.index}
              handlePrefill={headingActions.handlePrefill}
            />
          ))}
        </div>
        <span className="border-t border-light-borders-tertiary dark:border-dark-borders-tertiary flex-grow"></span>
      </div>
      <div className="flex flex-row flex-wrap gap-5">{children}</div>
    </div>
  );
};

const WrappedSubheadingDivider: React.FC<Props> = ({
  children,
  headingText,
  headingActions,
}) => {
  if (isReactNodeEmpty(children)) {
    return null;
  }
  return (
    <ErrorBoundary
      errorContext={`Error in SubheadingDivider ${headingText} ${children?.toString()}`}
    >
      <SubheadingDivider
        headingText={headingText}
        headingActions={headingActions}
      >
        {children}
      </SubheadingDivider>
    </ErrorBoundary>
  );
};

export default WrappedSubheadingDivider;
