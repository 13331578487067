import { RequestError } from '../common/utils';

/**
 *
 */
export const recursivelyUnpackErrorsFromUnknown = (
  unknownValue: unknown,
  seen = new WeakSet()
): unknown => {
  try {
    // If the value is a primitive, just return
    if (!unknownValue || typeof unknownValue !== 'object') return unknownValue;

    // Determine if we have parsed this value already
    if (seen.has(unknownValue)) {
      return '[Circular Reference]';
    }
    // Add value to seen
    seen.add(unknownValue);

    if (Array.isArray(unknownValue)) {
      // If it's an array, recursively check each element of an array
      return unknownValue.map((unknownValueItem) =>
        recursivelyUnpackErrorsFromUnknown(unknownValueItem, seen)
      );
    }

    let serialized: Record<string, unknown> = {};
    if (unknownValue instanceof Error) {
      serialized = {
        name: unknownValue.name,
        message: unknownValue.message,
        stack: unknownValue.stack,
        // Recursively check the cause property for more Errors
        cause: recursivelyUnpackErrorsFromUnknown(unknownValue.cause, seen),
      };
    }

    if (unknownValue instanceof AggregateError) {
      serialized.errors = unknownValue.errors.map((error) =>
        recursivelyUnpackErrorsFromUnknown(error, seen)
      );
    }

    // Include custom properties
    for (const key in unknownValue) {
      if (Object.prototype.hasOwnProperty.call(unknownValue, key)) {
        // Recursively check custom properties for more Errors
        serialized[key] = recursivelyUnpackErrorsFromUnknown(
          (unknownValue as unknown as Record<typeof key, unknown>)[key],
          seen
        );
      }
    }

    return serialized;
  } catch (error) {
    return { failedToParseError: true, unknownValue, error };
  }
};

const DEFAULT_ERROR_CODE = 500;
export const getStatusCodeFromUnknownError = (
  unknownValue: unknown
): number => {
  // If the value is a primitive, just return default
  if (!unknownValue || typeof unknownValue !== 'object') {
    return DEFAULT_ERROR_CODE;
  }

  if (
    'statusCode' in unknownValue &&
    typeof unknownValue.statusCode === 'number'
  ) {
    // If the value is an object with the key "statusCode" and it's a number, return
    return unknownValue.statusCode;
  }

  // Return default error code
  return DEFAULT_ERROR_CODE;
};

export const rethrowRequestError = (
  response: Response | RequestError
): Response => {
  if (response instanceof RequestError) {
    throw response;
  }

  return response;
};
