import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';

export const populateDriversLicense = (
  formMethods: UseFormReturn<AgentIntakeForm>,
  data: PrefillResponse | null,
  fieldIndex?: number
) => {
  const results = data?.responses;

  if (results) {
    results.forEach((dataItem) => {
      if (dataItem.target === 'driversLicense') {
        const { license } = dataItem.data;

        if (!license || Object.keys(license).length === 0) {
          return;
        }

        const fieldsToSet = {
          licenseNumber: license.licenseNumber,
          state: license.state,
        };

        Object.entries(fieldsToSet).forEach(([key, value]) => {
          const fieldKey =
            `drivers.${fieldIndex}.license.${key}` as keyof AgentIntakeForm;
          const existingValue = formMethods.getValues(fieldKey);
          if (!existingValue) {
            formMethods.setValue(fieldKey, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          }
        });
      }
    });
  }
};
