import { useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import Button from '../shared/button';
import VerticalDivider from '../shared/verticalDivider';
import Email from '@/components/documents/dialogs/email';
import Rename from '@/components/documents/dialogs/rename';
import Edit from '@/components/documents/dialogs/edit';
import Recategorize from '@/components/documents/dialogs/recategorize';
import Link from '@/components/documents/dialogs/link';
import type { Document } from '../../models/document/document';
import Tooltip from '../shared/tooltip';
import { documentConfig } from '@/services/common/config';
import styles from './actionBar.module.css';

import {
  accountIdAtom,
  associatedContactListAtom,
  categoryListAtom,
  customerAtom,
  documentsListAtom,
  opportunityListAtom,
  policyListAtom,
  selectedDocumentsListAtom,
  userNameAtom,
} from '@/store/documentsAtoms';

const dialogTypes = {
  EMAIL: 'email',
  RENAME: 'rename',
  EDIT_NOTE: 'note',
  RE_CATEGORIZE: 'categorize',
  CHANGE_LINKS: 'links',
};

export interface ActionBarProps {
  showAlertToast: any;
  refreshData: () => void;
}

const ActionBar = ({ showAlertToast, refreshData }: ActionBarProps) => {
  const userId = useAtomValue(accountIdAtom);
  const userName = useAtomValue(userNameAtom);
  const customer = useAtomValue(customerAtom);
  const opportunityList = useAtomValue(opportunityListAtom);
  const categoryList = useAtomValue(categoryListAtom);
  const associatedContactList = useAtomValue(associatedContactListAtom);
  const policyList = useAtomValue(policyListAtom);
  const selectedDocumentsList = useAtomValue(selectedDocumentsListAtom);
  const setDocumentsList = useSetAtom(documentsListAtom);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');

  const showDialog = (type: string) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const getSelectedDocumentListSize = () => {
    return selectedDocumentsList
      .map((document: Document) => document.size)
      .reduce((result: number, value: number) => result + value, 0);
  };

  const anyDocumentMissingCategory = () => {
    return selectedDocumentsList
      .map((document: Document) => document.documentType === '')
      .reduce((result: boolean, value: boolean) => result || value, false);
  };

  const getEmailTooltipMessage = () => {
    if (anyDocumentMissingCategory()) {
      return `Before emailing, please categorize: 
${selectedDocumentsList
  .filter((document: Document) => document.documentType === '')
  .map((document: Document) => ' •  ' + document.fileOriginalName)
  .join('\n')}`;
    } else if (
      getSelectedDocumentListSize() > parseInt(documentConfig.maxEmailSize)
    ) {
      return `We unfortunately can't send that many attachments. 
Please de-select some and try again.`;
    } else {
      return '';
    }
  };

  const deselectAll = () => {
    setDocumentsList((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        document.selected = false;
      });

      return documentListCopy;
    });
  };

  const updateField = (
    documentId: string,
    field: 'name' | 'link' | 'category' | 'note',
    value: any
  ) => {
    setDocumentsList((prevState: any) => {
      const documentsCopy = [...prevState];
      documentsCopy.forEach((document: Document) => {
        if (document.id === documentId) {
          switch (field) {
            case 'name':
              document.fileOriginalName = value;
              break;
            case 'link':
              document.loading = value;
              break;
            case 'category':
              document.documentType = value;
              break;
            case 'note':
              document.notes = value;
              break;
          }
        }
      });
      return documentsCopy;
    });
  };

  // TODO Refactor Dialog components to use jotai instead of props drilling
  const getDialogContent = () => {
    switch (dialogType) {
      case dialogTypes.EMAIL:
        return (
          <Email
            customer={customer}
            selectedDocumentList={selectedDocumentsList}
            associatedContactList={associatedContactList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
          />
        );
      case dialogTypes.RENAME:
        return (
          <Rename
            selectedDocument={selectedDocumentsList[0]}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.EDIT_NOTE:
        return (
          <Edit
            selectedDocumentList={selectedDocumentsList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.RE_CATEGORIZE:
        return (
          <Recategorize
            selectedDocumentList={selectedDocumentsList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            category={selectedDocumentsList[0].documentType}
            categoryList={categoryList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.CHANGE_LINKS:
        return (
          <Link
            selectedDocumentList={selectedDocumentsList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            linkedPolicyList={selectedDocumentsList[0]?.links?.policy ?? []}
            allPolicyList={policyList}
            linkedOpportunityList={
              selectedDocumentsList[0]?.links?.opportunity ?? []
            }
            allOpportunityList={opportunityList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            userId={userId}
            userName={userName}
            updateField={updateField}
          />
        );
      default:
        return;
    }
  };

  if (selectedDocumentsList.length === 0) {
    return;
  }

  return (
    <>
      <div className={styles.buttonContainer}>
        <div className={styles.selectedCount}>{`${
          selectedDocumentsList.length > 0 ? selectedDocumentsList.length : 'No'
        } ${
          selectedDocumentsList.length !== 1 ? 'files' : 'file'
        } selected`}</div>
        <VerticalDivider />
        <div>
          <Tooltip
            message={getEmailTooltipMessage()}
            disabled={
              !(
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              )
            }
            theme={'crm'}
          >
            <Button
              callback={() => showDialog(dialogTypes.EMAIL)}
              type={'text'}
              theme={'crm'}
              icon={'mail'}
              iconSize={18}
              disabled={
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              }
            >
              Email
            </Button>
          </Tooltip>
        </div>
        <VerticalDivider />

        <Button
          callback={() => showDialog(dialogTypes.RENAME)}
          type={'text'}
          theme={'crm'}
          icon={'edit'}
          disabled={selectedDocumentsList.length !== 1}
        >
          Rename
        </Button>
        <Button
          callback={() => showDialog(dialogTypes.EDIT_NOTE)}
          type={'text'}
          theme={'crm'}
          icon={'note'}
          disabled={selectedDocumentsList.length === 0}
        >
          Edit note
        </Button>
        <Button
          callback={() => showDialog(dialogTypes.RE_CATEGORIZE)}
          type={'text'}
          theme={'crm'}
          icon={'tag'}
          disabled={selectedDocumentsList.length === 0}
        >
          Change category
        </Button>
        <Button
          callback={() => showDialog(dialogTypes.CHANGE_LINKS)}
          type={'text'}
          theme={'crm'}
          icon={'link'}
          disabled={selectedDocumentsList.length === 0}
        >
          Link to...
        </Button>
      </div>
      {getDialogContent()}
    </>
  );
};

export { ActionBar };
