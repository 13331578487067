/* eslint-disable react-hooks/exhaustive-deps */
import { errorIsAuthError } from '@/components/context/AccessTokenContext';
import {
  ActionBanner,
  FormPage,
  Overlay,
  PrefillButton,
} from '@/components/forms';
import { SendToRaterDialog } from '@/components/forms/SendToRaterDialog';
import FenrisLegalAcknowledgementDialog from '@/components/intake/FenrisLegalAcknowledgementDialog';
import SideNav from '@/components/SideNav';
import { NavBarItem } from '@/components/SideNavItem';
import Switch from '@/components/Switch';
import { useAlertToast } from '@/controllers/common/hooks/useAlertToast';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { useWindowConfirm } from '@/controllers/common/hooks/utility/useWindowConfirm';
import { debounce } from '@/controllers/common/hooks/utility/utils';
import logger from '@/controllers/logger';
import { useAgentAndUserInfo } from '@/controllers/opportunity/hooks/useAgentAndUserInfo';
import { useData } from '@/controllers/opportunity/hooks/useData';
import { usePopulatePrefillData } from '@/controllers/opportunity/hooks/usePopulatePrefillData';
import { useQuoteSubmissionForApplication } from '@/controllers/opportunity/hooks/useQuoteSubmissionForApp';
import { useRaterIntegration } from '@/controllers/opportunity/hooks/useRaterIntegration';
import { useSendAndGetQuoteGroups } from '@/controllers/opportunity/hooks/useSendAndGetQuoteGroups';
import { IntakeNavOptions } from '@/services/common/constants';
import { agentIntake } from '@/services/forms/agent';
import type {
  AgentIntakeForm,
  DefaultValuesMap,
} from '@/services/forms/agent/schema';
import { defaultValuesMap } from '@/services/forms/agent/schema';
import {
  checkRequiredFieldsForRaters,
  ErrorType,
  getChangedFields,
  getCurrentFormattedDateTime,
  getErrorMessage,
  getMissingFieldsStringForRaters,
  getRaterButtonErrorMessage,
} from '@/services/forms/helpers/utils';
import { RaterProduct } from '@/models/opportunity/raterProductTypes';
import {
  checkRequiredFieldsForPrefill,
  getMissingFieldsString,
  getPrefillToast,
  getPrefillType,
  isPrefillButtonDisabled,
  showPrefillBanner,
} from '@/services/forms/prefill/prefillHelper';
import { postEav } from '@/services/opportunity/network/eav';
import { prefillData } from '@/services/opportunity/network/prefill';
import { flattenToEAV } from '@/services/opportunity/utils';
import {
  formSearch,
  SearchResultField,
} from '@/services/opportunity/utils/formSearch';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const AUTO_SAVE_INTERVAL = 15 * 1000; // 15 seconds

const onSubmit = (data: AgentIntakeForm) => {
  console.log(data);
};

const handleAutoSaveError = (cause: unknown) => {
  logger.error(
    'An error occurred while attempting to `autoSave` opportunity intake.',
    cause
  );
};

const agentIntakeFormSearch = (value: string) =>
  value ? formSearch(value, agentIntake) : null;

const OpportunityIntakePage = () => {
  const authedFetch = useAuthenticatedFetch();
  const formMethods = useForm<AgentIntakeForm>({
    defaultValues: agentIntake.defaults,
    mode: 'onBlur',
  });
  const { formData, loading, errorText, eavEntityId } = useData({
    setFormValue: formMethods.setValue,
  });
  const { raterProductEnabled } = useRaterIntegration({
    loading,
    crmAgentId: formData?.crm?.agentId,
  });
  const { crmData, agentName, userAgentFlags, confirmUserAgentFlag } =
    useAgentAndUserInfo({
      crmAgentId: formData?.crm?.agentId,
    });
  const { successQuoteGroups, updateSuccessQuoteGroups, successQRQuoteGroup } =
    useQuoteSubmissionForApplication({
      loading,
      applicationId: formData?.application?.id,
      raterProductEnabled,
    });
  const { AlertToastList, addToast, removeAllToast } = useAlertToast();

  const [selectedTab, setSelectedTab] = useState<NavBarItem>({
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANTS,
    label: 'Applicant(s)',
    search_count: null,
  });
  const currentTab = selectedTab.parent || selectedTab.value;
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [raterDialogOpen, setRaterDialogOpen] = useState(false);
  const [sendingToRater, setSendingToRater] = useState(false);
  const [prefillingData, setPrefillingData] = useState(false);
  const [
    confirmingFenrisLegalAcknowledgement,
    setConfirmingFenrisLegalAcknowledgement,
  ] = useState(false);

  const [
    showFenrisLegalAcknowledgementDialog,
    setShowFenrisLegalAcknowledgementDialog,
  ] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const [prefillSuccessful, setPrefillSuccessful] = useState(false);
  const [prefillParams, setPrefillParams] = useState<{
    prefillTarget: string;
    dynamicFieldIndex?: number;
  }>({
    prefillTarget: '',
  });

  const [searchResults, setSearchResults] = useState<
    SearchResultField[] | null
  >(null);

  const { isDirty, isValid, errors, dirtyFields } = formMethods.formState;

  useEffect(() => {
    if (formData) {
      formMethods.reset(formData, { keepDirty: true });
    }
  }, [formData, formMethods]);

  const onSave = useCallback(
    async (saveAll = false, forceSave = false) => {
      let saveError = false;
      let lastSavedTimeStamp = null;

      if (!isDirty && isValid && !forceSave) {
        lastSavedTimeStamp = getCurrentFormattedDateTime();
        saveError = false;
        // we don't save anything here, don't update lastSaved
        return { saveError, lastSavedTimeStamp: lastSaved };
      }

      if (!isValid || Object.keys(errors).length) {
        addToast([{ type: 'warning', text: getErrorMessage('InvalidFields') }]);
        saveError = true;
        return { saveError, lastSavedTimeStamp };
      }

      if (eavEntityId) {
        setIsSaving(true);

        try {
          const data = formMethods.getValues();
          const changedData = saveAll
            ? data
            : getChangedFields(data, dirtyFields);
          const eav = flattenToEAV(changedData, eavEntityId);

          if (!eav.length) {
            setIsSaving(false);
            lastSavedTimeStamp = getCurrentFormattedDateTime();
            saveError = false;
            return { saveError, lastSavedTimeStamp };
          }

          const result = await postEav({ body: eav, fetchFn: authedFetch });

          if (!result) {
            saveError = true;
            throw new Error('Failed to save EAV');
          }

          formMethods.reset(formMethods.getValues(), { keepValues: true });
          saveError = false;
          lastSavedTimeStamp = getCurrentFormattedDateTime();
        } catch (err) {
          if (!errorIsAuthError(err)) {
            // Assume auth errors have already been logged
            logger.log('Failed to save EAV', err);
          }
          addToast([
            {
              type: 'warning',
              text: 'Something went wrong while saving data. Please try again!',
            },
          ]);
          saveError = true;
        }

        setIsSaving(false);
      }

      return { saveError, lastSavedTimeStamp };
    },
    [isDirty, isValid, eavEntityId, formMethods, authedFetch, lastSaved]
  );

  const saveAll = useCallback(
    (forceSave = false) => {
      onSave(true, forceSave)
        .then(({ lastSavedTimeStamp }) => {
          setLastSaved(lastSavedTimeStamp);
        })
        .catch((err) => logger.error('failed to save form', err));
    },
    [onSave]
  );

  const handleFormBlur = useCallback(
    debounce(() => {
      onSave(false)
        .then(({ lastSavedTimeStamp }) => {
          setLastSaved(lastSavedTimeStamp);
        })
        .catch((err) => console.error('failed to save on form blur', err));
    }, 5000),
    [onSave]
  );

  useWindowConfirm(isDirty, onSave);

  useEffect(() => {
    const autoSave = async () => {
      if (isDirty && !isSaving) {
        try {
          const { lastSavedTimeStamp } = await onSave();
          setLastSaved(lastSavedTimeStamp);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    };

    if (isDirty && !isSaving && prefillSuccessful) {
      saveAll(true);
      setPrefillSuccessful(false);
    }

    const autoSaveInterval = setInterval(() => {
      autoSave().catch(handleAutoSaveError);
    }, AUTO_SAVE_INTERVAL);

    return () => clearInterval(autoSaveInterval);
  }, [isDirty, isSaving, onSave, prefillSuccessful]);

  useEffect(() => {
    if (
      confirmingFenrisLegalAcknowledgement &&
      userAgentFlags?.bwFenrisLegalAcknowledgement
    ) {
      setConfirmingFenrisLegalAcknowledgement(false);

      void handlePrefill(prefillParams);
    }
  }, [
    confirmingFenrisLegalAcknowledgement,
    userAgentFlags?.bwFenrisLegalAcknowledgement,
  ]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const results = agentIntakeFormSearch(value);
    setSearchValue(value);
    setSearchResults(results);
  };

  const { sendAndGetQuoteGroups } = useSendAndGetQuoteGroups();

  const handleSendRater = async (raterProductToSend: RaterProduct[]) => {
    const missingFields = checkRequiredFieldsForRaters(formMethods.getValues);
    if (missingFields.length) {
      const missingFieldString = getMissingFieldsStringForRaters(missingFields);
      addToast([
        {
          type: 'warning',
          text: `To send to raters, please add the ${missingFieldString}`,
        },
      ]);
      toggleRaterDialog();
      return;
    }

    try {
      setSendingToRater(true);
      const { saveError } = await onSave(true, true);
      if (!saveError) {
        if (!eavEntityId) {
          throw new Error('Missing entity id, unable to send to rater');
        }
        const { completedQuoteGroups, toasts } = await sendAndGetQuoteGroups(
          eavEntityId,
          raterProductToSend
        );
        updateSuccessQuoteGroups([
          ...completedQuoteGroups,
          ...successQuoteGroups,
        ]);
        addToast(toasts);
      }
    } catch (error) {
      console.log(error);
      const err = error as Error;
      const errorParams = { failureMessage: err.message };
      addToast([
        {
          type: 'warning',
          text: getErrorMessage(err.name as ErrorType, errorParams),
        },
      ]);
    }
    setSendingToRater(false);
    toggleRaterDialog();
  };

  const toggleRaterDialog = () => {
    if (!raterProductEnabled.length || !formData?.insuranceProducts) {
      const toastMessage = !raterProductEnabled.length
        ? getRaterButtonErrorMessage(
            agentName,
            formData?.crm?.agentId,
            crmData?.UserId
          )
        : 'Unsupported insurance products. Unable to send data to rater(s).';

      addToast([
        {
          type: 'warning',
          text: toastMessage,
        },
      ]);
      return;
    }
    setRaterDialogOpen(!raterDialogOpen);
  };

  const { isHomeTab, prefillType, prefillAddressType } =
    getPrefillType(currentTab);

  const prefillBannerMessage = `Save time by pre-filling key ${prefillType} information. Just ensure you've entered the ${prefillAddressType} address.`;

  const { populatePrefillData } = usePopulatePrefillData({ formMethods });

  const handleFenrisLegalAcknowledgementConfirm = () => {
    setConfirmingFenrisLegalAcknowledgement(true);

    void confirmUserAgentFlag('bwFenrisLegalAcknowledgement', authedFetch);
  };

  const handlePrefill = async ({
    prefillTarget,
    dynamicFieldIndex,
  }: {
    prefillTarget: string;
    dynamicFieldIndex?: number;
  }) => {
    removeAllToast();

    if (!userAgentFlags?.bwFenrisLegalAcknowledgement) {
      setShowFenrisLegalAcknowledgementDialog(true);
      setPrefillParams({
        prefillTarget,
        dynamicFieldIndex,
      });
      return;
    }

    const missingFields = checkRequiredFieldsForPrefill({
      prefillTarget,
      getValues: formMethods.getValues,
      fieldIdx: dynamicFieldIndex,
    });
    if (missingFields.length) {
      const missingFieldString = getMissingFieldsString({
        prefillTarget,
        missingFields,
      });
      addToast([
        {
          type: 'warning',
          text: `To pre-fill data, please add the ${missingFieldString}`,
        },
      ]);
      return;
    }

    if (!isValid || Object.keys(errors).length) {
      addToast([{ type: 'warning', text: getErrorMessage('InvalidFields') }]);
      return;
    }

    const currentFocus = document.activeElement;
    try {
      setPrefillingData(true);
      const result = await prefillData({
        target: prefillTarget,
        data: formMethods.getValues(),
        userId: crmData?.UserId,
        fetchFn: authedFetch,
        fieldIndex: dynamicFieldIndex,
      });

      const toast = getPrefillToast({ result, prefillType, prefillTarget });
      addToast([toast]);

      if (toast.type === 'success') {
        populatePrefillData({
          prefillTarget,
          data: result,
          fieldIndex: dynamicFieldIndex,
        });
        setPrefillSuccessful(true);
      }

      if (currentFocus instanceof HTMLElement) {
        currentFocus.focus();
      }
    } catch (error) {
      if (!errorIsAuthError(error)) {
        // Assume auth errors have already been logged
        logger.log('Failed to prefill data.', error);
      }
      addToast([
        {
          type: 'warning',
          text: "We're having trouble pre-filling data right now. Please contact Agent Support if this continues.",
          action: (
            <button
              className="underline"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() =>
                handlePrefill({ prefillTarget, dynamicFieldIndex })
              }
            >
              {'Try again'}
            </button>
          ),
        },
      ]);
    }
    setPrefillingData(false);
  };

  const overlayMessage =
    sendingToRater || confirmingFenrisLegalAcknowledgement
      ? 'Sending...'
      : prefillingData
      ? 'Pre-filling data...'
      : '';

  if (loading || errorText) {
    const loadingOrErrorText = loading ? 'Loading' : errorText;
    return (
      <div className="text-center text-xl mt-10">
        <span>{loadingOrErrorText}</span>
      </div>
    );
  }

  return (
    <div className="border border-[#E9E9E9] gap-5 h-full">
      <Overlay
        showOverlay={
          sendingToRater ||
          prefillingData ||
          confirmingFenrisLegalAcknowledgement
        }
        message={overlayMessage}
      />
      {AlertToastList}
      <SendToRaterDialog
        open={raterDialogOpen}
        handleSendRater={handleSendRater}
        toggleRaterDialog={toggleRaterDialog}
        raterProductEnabled={raterProductEnabled}
        successQRQuoteGroup={successQRQuoteGroup}
        agentMismatchName={agentName}
        policyType={formMethods.getValues('home.policy.type') || ''}
      />
      <FenrisLegalAcknowledgementDialog
        open={showFenrisLegalAcknowledgementDialog}
        setOpen={setShowFenrisLegalAcknowledgementDialog}
        confirm={handleFenrisLegalAcknowledgementConfirm}
      />
      <FormProvider {...formMethods}>
        <SideNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          onSearch={onSearch}
          isSaving={isSaving}
          isDirty={isDirty && Object.keys(dirtyFields).length > 0}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSaveAll={saveAll}
          lastSaved={lastSaved}
          toggleRaterDialog={toggleRaterDialog}
        />
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          onBlur={handleFormBlur}
        >
          <div className="flex flex-col ml-52 p-6 gap-8">
            <ActionBanner
              bannerMessage={prefillBannerMessage}
              bannerActions={
                <PrefillButton
                  handlePrefill={handlePrefill}
                  disableButton={isPrefillButtonDisabled(
                    isHomeTab,
                    formMethods.getValues()
                  )}
                  prefillType={prefillType}
                  currentTab={currentTab}
                />
              }
              showBanner={showPrefillBanner(currentTab)}
            />
            <Switch selectedKey={currentTab}>
              <FormPage
                key={IntakeNavOptions.APPLICANTS}
                page={agentIntake.pages.applicants}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.APPLICANT}
                page={agentIntake.pages.applicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.COAPPLICANT}
                page={agentIntake.pages.coApplicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.AUTO}
                page={agentIntake.pages.auto}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
                formMethods={formMethods}
                handlePrefill={handlePrefill}
              />
              <FormPage
                key={IntakeNavOptions.HOME}
                page={agentIntake.pages.property}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
              <FormPage
                key={IntakeNavOptions.FLOOD}
                page={agentIntake.pages.flood}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
            </Switch>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OpportunityIntakePage;
