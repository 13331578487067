import { FC } from 'react';
import { Button } from '@/components/ui/button';
import Tooltip from '@/components/shared/tooltip';

export type PrefillButtonProps = {
  handlePrefill: ({
    prefillTarget,
    dynamicFieldIndex,
  }: {
    prefillTarget: string;
    dynamicFieldIndex?: number;
  }) => void;
  disableButton: boolean;
  prefillType: string;
  currentTab: string;
};

const PrefillButton: FC<PrefillButtonProps> = ({
  handlePrefill,
  disableButton,
  prefillType,
  currentTab,
}) => {
  const tooltipMessage = `You've pre-filled data for ${prefillType}.`;
  const onClick = () => {
    handlePrefill({ prefillTarget: currentTab });
  };

  return (
    <Tooltip message={tooltipMessage} disabled={!disableButton} position="auto">
      <Button
        data-testid={'prefill-button'}
        className="w-[90px] bg-[#2C6DB7] gap-1 font-normal text-light-text-inverse py-1 pr-2 pl-2.5"
        onClick={onClick}
        disabled={disableButton}
        type={'button'}
      >
        Pre-fill data&hellip;
      </Button>
    </Tooltip>
  );
};

export { PrefillButton };
