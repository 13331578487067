import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useSetAtom, useAtom, atom } from 'jotai';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { getDocumentsForLinkId } from '@/services/document/documentService';
import { getDynamicsOpportunityDetails } from '@/services/opportunity/network/dynamics';
import DocumentsForOpportunity from './documents';
import logger from '@/controllers/logger';
import { useLoadSupportData } from './documents/useLoadSupportData';

import {
  accountIdAtom,
  opportunityIdAtom,
  userNameAtom,
  customerAtom,
  associatedContactListAtom,
  documentsListAtom,
  opportunityListAtom,
  policyListAtom,
  categoryListAtom,
  tableTextAtom,
} from '@/store/documentsAtoms';
import clientLogger from '@/controllers/logger';
import {
  errorIsAuthError,
  rethrowNonAuthError,
  useAccessToken,
} from '@/components/context/AccessTokenContext';

const errorTextAtom = atom<string>();
const documentsLoadingAtom = atom<boolean>(true);

const OpportunityDocuments = () => {
  const { id } = useParams();
  const opportunityId = useMemo(() => (id ? id.toLowerCase() : ''), [id]);
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();
  const { getAccessToken } = useAccessToken();

  const [accountId, setAccountId] = useAtom(accountIdAtom);
  const [userName, setUserName] = useAtom(userNameAtom);
  const [documentsList, setDocumentsList] = useAtom(documentsListAtom);
  const setCustomer = useSetAtom(customerAtom);
  const setAssociatedContactList = useSetAtom(associatedContactListAtom);
  const setOpportunityList = useSetAtom(opportunityListAtom);
  const setPolicyList = useSetAtom(policyListAtom);
  const setCategoryList = useSetAtom(categoryListAtom);
  const setTableText = useSetAtom(tableTextAtom);
  const setOpportunityId = useSetAtom(opportunityIdAtom);

  const [errorText, setErrorText] = useAtom(errorTextAtom);
  const [documentsLoading, setDocumentsLoading] = useAtom(documentsLoadingAtom);

  const getAccountId = useCallback(
    async (oppId: string) => {
      try {
        const accessToken = await getAccessToken();
        const oppDetails = await getDynamicsOpportunityDetails({
          accessToken,
          opportunityId: oppId,
          queryParams: {
            selectFields: 'name,_parentaccountid_value',
            expandFields: '',
          },
        });

        if (!oppDetails) {
          throw new Error('Failed to fetch account id from dynamics.');
        }
        setAccountId(oppDetails._parentaccountid_value);
      } catch (error) {
        if (errorIsAuthError(error)) {
          return; // Assume AuthError has already been logged
        }
        clientLogger.error('Failed to fetch account id from dynamics.');
      }
    },
    [getAccessToken, setAccountId]
  );

  const refreshDocsData = useCallback(async () => {
    setDocumentsLoading(true);
    try {
      if (!opportunityId) {
        throw new Error('Missing opportunity id');
      }
      if (!accountId) {
        throw new Error('Missing account id');
      }
      const opportunityDocs = await getDocumentsForLinkId(
        opportunityId,
        authedFetch
      );
      if (!opportunityDocs) {
        throw new Error('Unable to load documents for Opportunity');
      }

      setDocumentsList(opportunityDocs);
    } catch (error) {
      if (!errorIsAuthError(error)) {
        // Assume auth errors have already been logged
        clientLogger.error(
          'Failed to load documents for opportunity, please try again.',
          error
        );
      }
      setErrorText(
        'Failed to load documents for opportunity, please try again.'
      );
    }
    setDocumentsLoading(false);
  }, [accountId, authedFetch, opportunityId]);

  const { loadSupportData } = useLoadSupportData(authedFetch);

  useEffect(() => {
    if (documentsLoading) {
      setTableText({
        text: 'Loading documents...',
        subText: '',
        showSpinner: true,
      });
    } else if (errorText) {
      setTableText({ text: `${errorText}`, subText: '' });
    } else if (documentsList.length) {
      setTableText({
        text: "We couldn't find a matching document...",
        subText: '',
      });
    } else {
      setTableText({ text: 'Looks empty here...', subText: '' });
    }
  }, [documentsLoading, documentsList.length, setTableText, errorText]);

  useEffect(() => {
    if (!userName) {
      setUserName(instance.getActiveAccount()?.name || '');
    }
  }, [instance, userName, setUserName]);

  useEffect(() => {
    if (opportunityId && accountId) {
      refreshDocsData().catch(logger.error);
    }
  }, [opportunityId, accountId, authedFetch, refreshDocsData]);

  useEffect(() => {
    if (opportunityId && !accountId) {
      void getAccountId(opportunityId).catch(logger.error);
    }
  }, [opportunityId, authedFetch, getAccessToken, accountId, getAccountId]);

  useEffect(() => {
    if (accountId) {
      loadSupportData({
        accountId,
        setOpportunityList,
        setCategoryList,
        setCustomer,
        setAssociatedContactList,
        setPolicyList,
      })
        .catch(rethrowNonAuthError) // Assume auth errors have already been logged
        .catch(logger.error);
    }
  }, [accountId, authedFetch, loadSupportData]);

  useEffect(() => {
    if (opportunityId) {
      setOpportunityId(opportunityId);
    }
  }, [opportunityId, setOpportunityId]);

  return <DocumentsForOpportunity refreshData={() => void refreshDocsData()} />;
};

export default OpportunityDocuments;
