import { useContext } from 'react';
import { AccessTokenContext, AccessTokenContextValue } from './context';

/**
 * Custom hook to access the `AccessTokenContext`.
 *
 * This hook provides an interface to retrieve the access token
 * using the `AccessTokenContext`. It returns an object containing
 * the `getAccessToken` method, which can be called to obtain the
 * current access token.
 *
 * @throws {BrowserAuthError} If the `getAccessToken` fails authentication.
 * `getAccessToken` will report its own errors. There is no need to log these errors.
 * These errors are likely based in user settings and should be handled by the parent safely.
 *
 * @returns {AccessTokenContextValue} The context value with a method
 * to get the access token.
 */
const useAccessToken = (): AccessTokenContextValue => {
  return useContext(AccessTokenContext);
};

export default useAccessToken;
